<template>
  <c-modal class="update-available-modal" v-model="isOpen" @onClose="onCancel" giat="dialog-tam-icp-setup">
    <template #title> Update Available </template>
    <template #body>
      <c-row class="c-mt3">
        <c-column 75>
          <c-typography body> A new and improved version of the app is now available! Click refresh to get the latest update. </c-typography>
        </c-column>
        <c-column alignRight>
          <img src="./icon-success-large.svg" class="update-available-icon" alt="Success" />
        </c-column>
      </c-row>
    </template>
    <template #footer>
      <c-modal-actions>
        <c-row alignEndHorizontally>
          <c-button class="c-mr3" outlined action="neutral" @click="onCancel">Maybe later</c-button>
          <c-button @click="refreshApp">Refresh</c-button>
        </c-row>
      </c-modal-actions>
    </template>
  </c-modal>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { CButton, CColumn, CModal, CModalActions, CRow, CTypography } from "@enlyft/design-system-v2";

const isOpen = ref(true);
const emits = defineEmits(["on-close", "on-refresh"]);
function onCancel() {
  isOpen.value = false;
  emits("on-close");
}
function refreshApp() {
  isOpen.value = false;
  emits("on-refresh");
}
</script>
<style lang="scss">
.update-available-modal {
  > .c-modal-footer {
    padding-bottom: var(--spacing-2);
  }
  .update-available-icon {
    margin-top: calc(var(--spacing-8) * -1);
  }
}
</style>
