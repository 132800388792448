import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bb04828"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "aria-live--content",
  "aria-live": "polite"
}
const _hoisted_2 = { class: "c-text-center c-mt3 c-px4" }
const _hoisted_3 = { id: "toasts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_c_icon = _resolveComponent("c-icon")!
  const _component_c_typography = _resolveComponent("c-typography")!
  const _component_c_circular_progress = _resolveComponent("c-circular-progress")!
  const _component_c_modal = _resolveComponent("c-modal")!
  const _component_update_available_modal = _resolveComponent("update-available-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($options.layout), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.sharedStore.ariaLiveText), 1),
    _createElementVNode("div", {
      id: "intercom-trigger",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggleIntercom && $options.toggleIntercom(...args)))
    }, [
      _createVNode(_component_c_icon, {
        name: "message_bubble",
        size: "large"
      })
    ]),
    _createVNode(_component_c_modal, {
      modelValue: $data.showLoadingModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.showLoadingModal) = $event))
    }, {
      body: _withCtx(() => [
        _createVNode(_component_c_typography, { center: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Loading")
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_c_circular_progress, {
            color: "#1F2929",
            size: "xs"
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.$store.state.updateExist && !_ctx.$store.state.needRefreshBar)
        ? (_openBlock(), _createBlock(_component_update_available_modal, {
            key: 0,
            onOnClose: $options.dontRefresh,
            onOnRefresh: $options.refreshApp
          }, null, 8, ["onOnClose", "onOnRefresh"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}