<template>
  <div id="sidebar-layout">
    <div id="headerNav">
      <div class="hidden">
        <!-- This is used only for authentication on knowledge base and must be hidden -->
        <!-- It is imported on 'main.ts' using 'app.use(AnnounceKit)' -->
        <AnnounceKit widget="https://support.enlyft.com/widgets/v2/4B6XXG" :user="announceKitUserProp" :data="announceKitSegmentationProp" />
      </div>
      <refresh-bar v-if="showRefreshBar" />
      <c-s-user-bar v-if="showCSUserBar" :teamName="activeTeam?.account_name" />
      <unsupported-browser-bar v-if="showUnsupportedBrowserBar" />
    </div>
    <div class="sidebar">
      <component :is="sideBar" />
    </div>

    <div class="maincontent" v-bind:class="[isSidebarExpanded ? 'wideSideBar' : 'narrowSideBar']" :style="styleMainContent">
      <div class="container-fluid main" :style="styleMain">
        <slot />
      </div>
    </div>
    <footer-bar />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, nextTick } from "vue";
import FooterBar from "@/shared/FooterBar.vue";
import { useShared } from "@/stores/shared/store";
import { mapActions, mapGetters, mapState } from "vuex";
import { mapStores } from "pinia";
import CSUserBar from "@/shared/CSUserBar.vue";
import RefreshBar from "@/shared/RefreshBar.vue";
import UnsupportedBrowserBar from "@/shared/UnsupportedBrowserBar.vue";
import { Rights, User } from "@/shared/user";
import { routeToStayOn } from "@/shared/RouteReloadUtils";
import { useSavedAccounts } from "@/stores/saved-accounts/store";
import { useSavedContacts } from "@/stores/saved-contacts/store";
import { useIntegrations } from "@/stores/integrations/store";
import utils from "@/shared/utils";

const alertBarsHeight = 34;

const SideBar = defineAsyncComponent(() => import("@/shared/SideBar.vue"));

// eslint-disable-next-line
let unsubscribeTeamSelectionMutation: () => void;
// eslint-disable-next-line
let unsubscribeModelSelectionMutation: () => void;

export default {
  name: "sidebar-layout",
  components: {
    FooterBar,
    CSUserBar,
    RefreshBar,
    UnsupportedBrowserBar,
  },
  data(): {
    isMounted: boolean;
    styleMain: any;
    styleMainContent: any;
    sideBar: any;
    userAgent: any;
  } {
    return {
      isMounted: false,
      styleMainContent: {
        minHeight: "calc(100vh - 30px - 116px)",
      },
      styleMain: {
        minHeight: "calc(100vh - 30px - 2rem - 116px)",
      },
      sideBar: SideBar,
      userAgent: undefined,
    };
  },
  methods: {
    ...mapActions({
      getJWT: "login/getJWT",
      setActiveIntegrations: "integrations/setActiveIntegrations",
      getTeamModels: "model/getTeamModels",
      setActiveModel: "model/setActiveModel",
      getTeamFeatures: "team/getTeamFeatures",
      setTeamFeatures: "team/setTeamFeatures",
      unsetRedirect: "login/unsetRedirect",
    }),
    getHeaderHeight() {
      const showRefreshbar = this.$store.state.needRefreshBar;
      let header = document.getElementById("layout-header-div");
      let ai_header = document.getElementById("ai-header");
      let headerTopPx = 0;
      if (showRefreshbar) headerTopPx += alertBarsHeight;
      if (this.showUnsupportedBrowserBar) headerTopPx += alertBarsHeight;
      if (this.showCSUserBar) headerTopPx += alertBarsHeight;
      if (ai_header) {
        try {
          if (headerTopPx) {
            ai_header.style.top = `${headerTopPx}px`;
          }
        } catch (err) {
          console.error("Error setting style for ai header div", err);
        }
        return;
      } else if (header) {
        try {
          if (headerTopPx) {
            header.style.top = `${headerTopPx}px`;
          }
        } catch (err) {
          console.error("Error setting style for header div", err);
        }
        return header.clientHeight;
      } else {
        return 116;
      }
    },
    setStyleMain() {
      const showRefreshbar = this.$store.state.needRefreshBar;
      let headerTopPx = 0;
      if (showRefreshbar) headerTopPx += alertBarsHeight;
      if (this.showUnsupportedBrowserBar) headerTopPx += alertBarsHeight;
      if (this.showCSUserBar) headerTopPx += alertBarsHeight;
      let headerHeight = this.getHeaderHeight();
      if (showRefreshbar) {
        headerHeight = headerHeight ? headerHeight + alertBarsHeight : alertBarsHeight;
      }
      const marginTop = (headerHeight ? headerHeight + 24 + headerTopPx : 116 + headerTopPx) + "px";
      const minHeight = "calc(100vh - " + (headerHeight ? headerHeight + 24 + headerTopPx : 116 + headerTopPx) + "px)";

      if (this.styleMain.minHeight !== minHeight) {
        this.styleMain.minHeight = minHeight;
      }

      if (this.styleMain.marginTop !== marginTop && !this.$route.path?.includes("/insights")) {
        this.styleMain.marginTop = marginTop;
      } else if (this.$route.path?.includes("/insights")) {
        // no spacing for insights views
        this.styleMain.marginTop = headerTopPx + "px";
      }
    },
    setStyleMainContent() {
      const headerHeight = this.getHeaderHeight();
      const minHeight = "calc(100vh - 30px - 2rem - " + (headerHeight ? headerHeight + 24 : 116) + "px)";
      if (this.styleMainContent.minHeight !== minHeight) {
        this.styleMainContent.minHeight = minHeight;
      }
    },
    setStyles() {
      this.setStyleMain();
      this.$nextTick(() => {
        this.setStyleMainContent();
      });
    },
  },
  computed: {
    ...mapStores(useShared),
    ...mapState("team", {
      activeTeam: "activeTeam",
    }),
    ...mapState("model", {
      teamModels: "activeTeamModels",
    }),
    ...mapState("login", {
      userTeams: "teams",
      redirect: "redirect",
    }),
    ...mapGetters("login", {
      loggedInUser: "getUser",
    }),
    isSidebarExpanded() {
      return this.sharedStore.isSidebarExpanded;
    },
    showUnsupportedBrowserBar() {
      return !!(
        this.userAgent &&
        (this.userAgent.indexOf("Trident") > -1 ||
          this.userAgent.indexOf("MSIE") > -1 ||
          this.userAgent.indexOf("Edge/18.") > -1 ||
          this.userAgent.indexOf("Edge/17.") > -1 ||
          this.userAgent.indexOf("Edge/16.") > -1 ||
          this.userAgent.indexOf("Edge/15.") > -1)
      );
    },
    showCSUserBar() {
      return this.activeTeam?.role === "enlyft_cs" && !this.$route.path?.includes("/teamSelection");
    },
    announceKitUserProp() {
      return {
        id: this.loggedInUser?.id,
        email: this.loggedInUser?.email,
        name: this.loggedInUser?.name,
      };
    },
    announceKitSegmentationProp(): any {
      return {
        email: this.loggedInUser?.email,
        name: this.loggedInUser?.name,
        role: this.loggedInUser?.role,
        team: this.teamName,
        member_type: this.loggedInUser?.organization,
      };
    },
    showRefreshBar(): boolean {
      return this.$store.state.needRefreshBar && this.loggedInUser && this.$route.name !== "loginEmail" && this.$route.name !== "landing";
    },
  },
  created() {
    this.userAgent = navigator.userAgent;
  },
  mounted() {
    this.setStyles();

    unsubscribeModelSelectionMutation = this.$store.subscribe(async (mutations, state) => {
      if (!mutations.type || !mutations.payload) {
        return;
      }

      let totalOfferings = this.teamModels && Array.isArray(this.teamModels) ? this.teamModels.length : 0;
      utils.updateIntercom(this.loggedInUser, this.activeTeam, state.model.activeModel, totalOfferings);
      switch (mutations.type) {
        // when the solution changes
        case "model/ActiveModel":
          if (!state.model.activeModel) {
            break;
          }

          // If there is a modelId in query and it's outdated, it needs to be replaced with the current one (avoids page refresh loop)
          const queryModelId = this.$route.query?.modelId;
          if (queryModelId && queryModelId !== state.model.activeModel._id) {
            this.$router.replace({
              query: {
                ...this.$route.query,
                modelId: state.model.activeModel._id,
              },
            });
            await nextTick();
          }
          try {
            // reset pinia stores
            const savedAccountsStore = useSavedAccounts();
            const savedContactsStore = useSavedContacts();
            const integrationsStore = useIntegrations();

            savedAccountsStore.$reset();
            // triggers an empty action that can be subscribed to. Wasn't able to find a way to detect when the store is reset
            savedAccountsStore.resetState();
            savedContactsStore.$reset();
            // triggers an empty action that can be subscribed to. Wasn't able to find a way to detect when the store is reset
            savedContactsStore.resetState();

            integrationsStore.$reset();

            utils.setUserProperties(this.loggedInUser, this.activeTeam, state.model.activeModel);
            utils.updateIntercom(this.loggedInUser, this.activeTeam, state.model.activeModel, totalOfferings);
            if ((window as any).mixpanel && (window as any).mixpanel.app) {
              (window as any).mixpanel.app.reset;

              utils.setMixPanelUserProperties(this.loggedInUser, this.activeTeam, state.model.activeModel, this.userTeams);
            }
          } catch (ex) {
            console.error("Unknown analytics failure " + ex);
          }
          if (state.model.activeModel?._id) {
            // clear "cached" datasets and active integrations when activeModel changes
            // because those are specific to each model
            utils.sendMixPanelEvent("UserAccountFlags", this.activeTeam?.user_account_flags);
            this.setActiveIntegrations(null);
          } else {
            // if there is no active model set the remaining code is not needed
            return;
          }

          // if sub is single model it is not needed to reload.
          if (state.model?.activeTeamModels?.length === 1) return;

          // determine if we can stay on this page after the solution changes
          // Ideally all pages would work, but we need to add support for specific pages as we deal with the nuances
          const stay = routeToStayOn(this.$route, state.model.activeModel._id);
          // redirect is set when user tried to acces any APP URL but is not logged in
          // it stores the route to redirect to it after login and sub/model chosen
          if (this.redirect) {
            this.$router.push(this.redirect);
            this.unsetRedirect();
            return;
          } else if (stay) {
            if (stay === "reload") {
              // show a loader while we wait
              this.showLoadingModal = true;
              // setTimeout with 0 sec is to push the refresh to the end of JS thread
              // we need to make make sure it waits the rest of the operations
              // note: this fixes a bug of reloading before modelId query param is updated
              setTimeout(() => {
                this.$router.go(0);
              }, 0);
              return;
            }
            // keep query to keep filter params
            if ((stay as any).name && ["savedcontacts", "saveddatasets", "insights", "insights-technologies", "insights-people"].includes((stay as any).name)) {
              const stayWithQuery = {
                ...stay,
                query: {
                  ...this.$route.query,
                  modelId: state.model.activeModel._id,
                },
              };
              this.$router.push(stayWithQuery);
            } else this.$router.push(stay);
            return;
          }
          // this logic is now on model selection page
          // and on login flow
          // kept in case we need it again
          // else {
          //   this.showLoadingModal = true;
          //   const savedSearches = await this.discoverStore.getOwnedSearches();
          //   // if there are saved searches redirect to Engage
          //   if (savedSearches?.length > 0 && !(this.userStore.hasDashboardPage && this.isAdminOrManager)) {
          //     this.$router.push({
          //       path: "/saved-searches",
          //     });
          //     this.showLoadingModal = false;
          //     return;
          //   }
          //   this.showLoadingModal = false;
          //   if (this.userStore.hasDashboardPage && this.isAdminOrManager) {
          //     this.$router.push({
          //       name: "dashboard",
          //     });
          //   } else {
          //     this.$router.push({
          //       name: "filteraccounts",
          //       query: { modelId: state.model.activeModel._id },
          //     });
          //   }
          // }
          break;
      }
    });

    unsubscribeTeamSelectionMutation = this.$store.subscribe((mutation: any, state: any) => {
      switch (mutation.type) {
        case "team/ActiveTeam":
          if (!state.team.activeTeam) {
            break;
          }
          let currentUser = state.login.user;
          let currentTeam = state.team.activeTeam;
          try {
            utils.setUserProperties(currentUser, currentTeam);
            utils.updateBeamer(currentUser, currentTeam);
            utils.setMixPanelUserProperties(currentUser, currentTeam, state.model.activeModel, state.login.teams);
          } catch (e) {
            console.error("Error updating user/active team for analytics." + e);
          }

          const getModels = async () => {
            try {
              const models = await this.getTeamModels();
              if (!models || !Array.isArray(models) || !models.length) {
                this.setActiveModel(undefined);
                this.$router.push({
                  name: "modelSelection",
                });
                return;
              }
              if (models.length > 1) {
                // sets active model and redirect if theres a valid modelId in redirect query
                if (this.$route.query?.modelId || this.redirect?.query?.modelId) {
                  const modelId = this.$route.query?.modelId || this.redirect.query.modelId;
                  const foundModel = models.find((model) => model._id === modelId);
                  if (foundModel) {
                    // set active model mutation subscription will take care of redirection
                    this.setActiveModel(foundModel);
                  } else {
                    this.setActiveModel(models[0]);
                  }
                } else {
                  // if theres more than one model and no model is active or model active does not belong to current team redirects to model selection page
                  if (!state.model.activeModel || !models.some((model) => model._id === state.model.activeModel._id)) {
                    this.$router.push({
                      name: "modelSelection",
                    });
                  }
                }
              } else {
                // if single model and no redirect sets the model and go to search page
                if (!this.redirect) {
                  this.setActiveModel(models[0]);
                  if (this.userStore.hasDashboardPage && this.isAdminOrManager) {
                    this.$router.push({
                      name: "dashboard",
                    });
                  } else {
                    this.$router.push({
                      name: "filteraccounts",
                    });
                  }
                } else {
                  // if redirection model selection subscription takes care of redirection
                  this.setActiveModel(models[0]);
                }
              }
            } catch (err) {
              console.error("error: teamSelection subscribe in navbar getTeamModels", err);
            }
          };
          this.getJWT({ accountId: currentUser.id, teamId: currentTeam.account_id, apiKey: currentUser.api_key })
            .then(() => {
              this.getTeamFeatures(currentTeam.account_id).then((data: any) => {
                this.setTeamFeatures(data);
              });
              const user: User = this.loggedInUser;
              if (user && user.can(Rights.ShowPayment, state.team.activeTeam)) {
                this.$router.push({
                  name: "subscription",
                });
              } else {
                getModels();
              }
            })
            .catch((err: any) => {
              if (err && err.internal_code === 471) {
                this.$router.push({
                  name: "loginEmail",
                });
              }
            });

          break;

        default:
          break;
      }
    });
  },
  updated() {
    this.setStyles();
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler() {
        await this.$nextTick();
        setTimeout(() => {
          this.setStyles();
        }, 100);
      },
    },
  },
};
</script>
<style>
@media (max-width: 1290px) {
  #sidebar-layout > div.maincontent.wideSideBar > div {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .layout-header-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  #ai-header-container {
    padding-left: 39px !important;
    padding-right: 39px !important;
  }
}
.app-div div.layout-header {
  /* I don't love this, but the header is getting overlapped by the checkbox label component which used z-index: 100
  At some point I would like to replace the header component @kenyon Oct 2022
  */
  z-index: 101;
}
</style>
<style lang="scss" scoped>
.hidden {
  display: none;
}
#headerNav {
  max-height: 34px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
}
#sidebar-layout {
  position: relative;
  min-height: calc(100vh - 25px);

  .sidebar {
    position: fixed;
    top: 100;
    left: 0;
    z-index: 102;
    background: #e4e5eb;
    display: flex;
    color: #202629;
    box-shadow: inset -0.5px 0px 0px rgba(159, 162, 174, 0.6);
  }
  .maincontent {
    background: #ffffff;
    min-height: calc(100vh - 30px - 116px);
    display: flex;
    margin-top: 0;
    transition: ease 150ms all;
  }
  .main {
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 30px - 2rem - 116px);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    max-width: var(--container-max-width);
    padding: 0px 0px 60px 0px !important;
  }
}
.wideSideBar {
  margin-left: var(--sidebar-expanded);
}
.narrowSideBar {
  margin-left: var(--sidebar-narrow);
}
</style>
