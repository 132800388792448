import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a590c61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "sidebar-layout" }
const _hoisted_2 = { id: "headerNav" }
const _hoisted_3 = { class: "hidden" }
const _hoisted_4 = { class: "sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnnounceKit = _resolveComponent("AnnounceKit")!
  const _component_refresh_bar = _resolveComponent("refresh-bar")!
  const _component_c_s_user_bar = _resolveComponent("c-s-user-bar")!
  const _component_unsupported_browser_bar = _resolveComponent("unsupported-browser-bar")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AnnounceKit, {
          widget: "https://support.enlyft.com/widgets/v2/4B6XXG",
          user: $options.announceKitUserProp,
          data: $options.announceKitSegmentationProp
        }, null, 8, ["user", "data"])
      ]),
      ($options.showRefreshBar)
        ? (_openBlock(), _createBlock(_component_refresh_bar, { key: 0 }))
        : _createCommentVNode("", true),
      ($options.showCSUserBar)
        ? (_openBlock(), _createBlock(_component_c_s_user_bar, {
            key: 1,
            teamName: _ctx.activeTeam?.account_name
          }, null, 8, ["teamName"]))
        : _createCommentVNode("", true),
      ($options.showUnsupportedBrowserBar)
        ? (_openBlock(), _createBlock(_component_unsupported_browser_bar, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($data.sideBar)))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["maincontent", [$options.isSidebarExpanded ? 'wideSideBar' : 'narrowSideBar']]),
      style: _normalizeStyle($data.styleMainContent)
    }, [
      _createElementVNode("div", {
        class: "container-fluid main",
        style: _normalizeStyle($data.styleMain)
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ], 6),
    _createVNode(_component_footer_bar)
  ]))
}