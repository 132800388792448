import axios, { AxiosResponse, AxiosError } from "axios";
import JSEncrypt from "jsencrypt";
import { IUser } from "@/shared/user";
import { IUserTeam } from "@/shared/user";
import * as Sentry from "@sentry/vue";
import { getMixpanelRef } from "./utils/mixpanel";
import { isBrowserExtension } from "@/chrome_plugin/utils/environment";
import * as EmailValidator from "email-validator";

const API_STUB: string = process.env.VUE_APP_API || "[VUE_APP_API MISSING!]";
const MODEL_API: string = process.env.VUE_APP_MODEL_API || "[VUE_APP_MODEL_API MISSING!]";
const SSO_URL: string = process.env.VUE_APP_SSO_URL || "[VUE_APP_SSO_URL MISSING!]";
const AZURE_AD_CLIENT_ID: string = process.env.VUE_APP_AZURE_AD_CLIENT_ID || "[VUE_APP_AZURE_AD_CLIENT_ID MISSING!]";
const PIPEDRIVE_OAUTH_URL: string = process.env.VUE_APP_PIPEDRIVE_OAUTH_URL || "[VUE_APP_PIPEDRIVE_OAUTH_URL MISSING!]";
const PIPEDRIVE_CLIENT_ID: string = process.env.VUE_APP_PIPEDRIVE_CLIENT_ID || "[VUE_APP_PIPEDRIVE_CLIENT_ID MISSING!]";
const D365_CLIENT_ID: string = process.env.VUE_APP_D365_CLIENT_ID || "[VUE_APP_D365_CLIENT_ID MISSING!]";
const SF_CLIENT_ID: string = process.env.VUE_APP_AZURE_SF_CONSUMER_KEY || "[VUE_APP_AZURE_SF_CONSUMER_KEY MISSING!]";
const SF_OLD_CLIENT_ID: string = process.env.VUE_APP_AZURE_SF_OLD_CONSUMER_KEY || "[VUE_APP_AZURE_SF_OLD_CONSUMER_KEY MISSING!]";
const SF_REDIRECT_URI: string = process.env.VUE_APP_AZURE_SF_REDIRECT_URL || "[VUE_APP_AZURE_SF_REDIRECT_URL MISSING!]";
const HUBSPOT_CLIENT_ID: string = process.env.VUE_APP_HUBSPOT_CONSUMER_KEY || "[VUE_APP_HUBSPOT_CONSUMER_KEY MISSING!]";
const HUBSPOT_REDIRECT_URI: string = process.env.VUE_APP_HUBSPOT_REDIRECT_URL || "[VUE_APP_HUBSPOT_REDIRECT_URL MISSING!]";
const HUBSPOT_SCOPES_STR: string = process.env.VUE_APP_HUBSPOT_SCOPES_STR || "[VUE_APP_HUBSPOT_SCOPES_STR MISSING!]";
const PIPEDRIVE_REDIRECT_URI: string = process.env.VUE_APP_PIPEDRIVE_REDIRECT_URI || "[VUE_APP_PIPEDRIVE_REDIRECT_URI MISSING!]";
const BUILD_VERSION: string = process.env.VUE_APP_BUILD_VERSION || "[VUE_APP_BUILD_VERSION MISSING!]";

const OUTREACH_CLIENT_ID: string = process.env.VUE_APP_OUTREACH_APP_ID || "[VUE_APP_OUTREACH_APP_ID MISSING!]";
const OUTREACH_OAUTH_ENDPOINT: string = process.env.VUE_APP_OUTREACH_OAUTH_ENDPOINT || "[VUE_APP_OUTREACH_OAUTH_ENDPOINT MISSING!]";
const OUTREACH_REDIRECT_URI: string = process.env.VUE_APP_OUTREACH_REDIRECT_URI || "[VUE_APP_OUTREACH_REDIRECT_URI MISSING!]";
const OUTREACH_SCOPES_STR: string = process.env.VUE_APP_OUTREACH_SCOPES_STR || "[VUE_APP_OUTREACH_SCOPES_STR MISSING!]";
const LOW_CONFIDENCE_THRESHOLD = 40;
const MODERATE_CONFIDENCE_THRESHOLD = 65;

const RSA_PUBLIC_KEY: string | undefined = process.env.VUE_APP_RSA_PUBLIC_KEY || undefined;

const storageVersion: number = 1;

const integrationsNameMap = {
  dynamics: "Dynamics",
  pipedrive: "Pipedrive",
  marketo: "Marketo",
  hubspot: "HubSpot",
  outreach: "Outreach",
  salesforce: "Salesforce",
};

const utils: any = {
  API_STUB,
  MODEL_API,
  SSO_URL,
  AZURE_AD_CLIENT_ID,
  BUILD_VERSION,
  PIPEDRIVE_CLIENT_ID,
  PIPEDRIVE_OAUTH_URL,
  PIPEDRIVE_REDIRECT_URI,
  D365_CLIENT_ID,
  SF_CLIENT_ID,
  SF_OLD_CLIENT_ID,
  SF_REDIRECT_URI,
  HUBSPOT_CLIENT_ID,
  HUBSPOT_REDIRECT_URI,
  HUBSPOT_SCOPES_STR,
  OUTREACH_CLIENT_ID,
  OUTREACH_OAUTH_ENDPOINT,
  OUTREACH_REDIRECT_URI,
  OUTREACH_SCOPES_STR,
  getIntegrationName(integration: string): string {
    const name = integrationsNameMap[integration];
    return name || integration;
  },
  validateEmail(email: string): boolean {
    return EmailValidator.validate(email);
  },
  decodeJWT(token: string): object {
    const base64Url: string = token.split(".")[1];
    const base64: string = decodeURIComponent(
      atob(base64Url)
        .split("")
        .map(function (c: string): string {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(base64);
  },
  clearStorage(): void {
    sessionStorage.clear();
    localStorage.clear();
    const cookies: any = $cookies;
    if (cookies) {
      cookies.keys().forEach((cookie: string) => {
        if (cookie !== "enlyft.lastused") {
          cookies.remove(cookie);
        }
      });
      // expire cookies from app.enlyft.com
      this.deleteAllCookies();
    }
  },
  deleteAllCookies() {
    const allCookies = document.cookie.split(";");
    // The "expire" attribute of every cookie is set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (let i = 0; i < allCookies.length; i++) document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
  },
  removeCookie(key: string): void {
    const cookies: any = $cookies;
    if (cookies) {
      cookies.keys().forEach((cookie: string) => {
        if (cookie === key) {
          cookies.remove(cookie);
        }
      });
    }
  },
  removeStorage(name: string): boolean {
    try {
      localStorage.removeItem(name);
      localStorage.removeItem(name + "_expiresIn");
    } catch (e) {
      console.error("removeStorage: Error removing key [" + name + "] from localStorage: " + JSON.stringify(e));
      return false;
    }
    return true;
  },
  setStorage(key: string, value: any, expires?: number): boolean {
    if (expires === undefined || expires === null) {
      expires = 24 * 60 * 60 * 7; // default: seconds for 7 day
    } else {
      expires = Math.abs(expires); // make sure it's positive
    }

    const now: number = Date.now(); // millisecs since epoch time, lets deal only with integer
    const schedule: number = now + expires * 1000;
    try {
      localStorage.setItem(key, value);
      localStorage.setItem(key + "_expiresIn", schedule + "");
    } catch (e) {
      console.error("setStorage: Error setting key [" + key + "] in localStorage: " + JSON.stringify(e));
      return false;
    }
    localStorage.setItem("storageVersion", storageVersion + "");
    return true;
  },
  getAppVersion(): string {
    if (!document || !document.body) {
      return "n/a";
    }
    const v = document.body.getAttribute("data-build");
    if (!v || (v && typeof v === "string" && v.length === 0)) {
      return "n/a";
    }
    return v;
  },
  genericSuccessHandler(resolve: (data: any) => void, reject: (data: any) => void): (response: AxiosResponse) => void {
    return (response: AxiosResponse) => {
      if (response && response.data && response.data.data) {
        resolve(response.data.data);
      } else if (response && response.data) {
        resolve(response.data);
      } else {
        reject(new Error("no data"));
      }
    };
  },
  addtDataSuccessHandler(resolve: (data: any) => void, reject: (data: any) => void): (response: AxiosResponse) => void {
    return (response: AxiosResponse) => {
      if (response && response.data && response.data.data && response.data.additionl_data) {
        resolve({ data: response.data.data, additionl_data: response.data.additionl_data });
      } else if (response && response.data && response.data.data) {
        resolve(response.data.data);
      } else if (response && response.data) {
        resolve(response.data);
      } else {
        reject(new Error("no data"));
      }
    };
  },
  genericErrorHandler(resolve: (data: any) => void, reject: (data: any) => void): (error: AxiosError) => void {
    return (error: AxiosError) => {
      // there may be some cases where we actually want to resolve a valid response
      // but that come through a non 2xx status code
      // if (error && error.response) {
      //   reject(error.response.data);
      // } else {
      if (error?.code === "ERR_CANCELED") {
        // ignore request cancellation errors
        // we don't want them surfacing to the user or sentry
        return;
      }
      reject(error);
      // }
    };
  },
  tokenErrorHandler(resolve: (data: any) => void, reject: (data: any) => void): (error: AxiosError) => void {
    return (error: AxiosError) => {
      if (error?.code === "ERR_CANCELED") {
        // ignore request cancellation errors
        // we don't want them surfacing to the user or sentry
        return;
      }
      if (error && error.response) {
        reject(error.response.data);
      } else {
        reject(error);
      }
    };
  },
  postAndCancelPrevious() {
    let previous: any;
    return (url: string, data: object) => {
      if (previous) {
        previous.cancel("Only one request allowed at a time.");
      }
      previous = axios.CancelToken.source();
      return axios.post(url, data, {
        cancelToken: previous.token,
      });
    };
  },
  getAndCancelPrevious() {
    let previous: any;
    return (url: string) => {
      if (previous) {
        previous.cancel("Only one request allowed at a time.");
      }
      previous = axios.CancelToken.source();
      return axios.get(url, {
        cancelToken: previous.token,
      });
    };
  },
  // tools utils functions (intercom, smartlook, sentry, google analytics)
  setUserProperties(user: IUser, activeTeam: any, activeModel?: any): void {
    const mixpanelRef = getMixpanelRef();
    const currentTeam: string = activeTeam ? activeTeam.account_name : "";
    const offeringType: string = activeModel ? activeModel.model_type : "";
    if (!user) {
      return;
    }
    const id: any = user.id;
    const name: any = user.name;
    const userId: any = name + "-" + id;
    const userRole: any = activeTeam ? activeTeam.role : "";
    const plan_name: string = activeTeam && activeTeam.plan_name ? activeTeam.plan_name : "";
    const user_createdAt: any = user.creation_time ? user.creation_time : "";

    try {
      if ((window as any).smartlook) {
        (window as any).smartlook("identify", user.id, {
          email: user.email,
          name: user.name,
          role: userRole,
          team: currentTeam,
          offering: offeringType,
          plan: plan_name,
        });
      }
    } catch (e) {
      console.error("Unknown Smartlook failure " + e);
    }

    try {
      (window as any).LogRocket.identify(id, {
        email: user.email,
        name: user.name,
        role: userRole,
        team: currentTeam,
        plan: plan_name,
      });
    } catch (e) {
      console.error("Unknown LogRocket failure " + e);
    }

    if (plan_name && plan_name === "Free Plan") {
      try {
        (window as any).Intercom("shutdown");
      } catch (e) {
        console.error("Unknown Intercom failure " + e);
      }
    } else {
      try {
        (window as any).Intercom("update", {
          email: user.email,
          name: user.name,
          role: userRole,
          team: currentTeam,
          offering: offeringType,
          plan: plan_name,
        });
      } catch (e) {
        console.error("Unknown Intercom failure " + e);
      }
      //TBD: delete above if intercomSettings work as expected.
      try {
        (window as any).intercomSettings = {
          email: user.email,
          name: user.name,
          role: userRole,
          team: currentTeam,
          offering: offeringType,
          plan: plan_name,
        };
      } catch (e) {
        console.error("Unknown Intercom failure " + e);
      }
    }

    try {
      const sentryScope = Sentry.getCurrentScope();
      sentryScope.setUser({ email: user.email, id: userId });
      sentryScope.setExtra("sessionURL", (window as any).LogRocket.sessionURL);
      sentryScope.setExtra("team", currentTeam);
      sentryScope.setExtra("role", userRole);
      sentryScope.setTag("team", currentTeam);
      sentryScope.setTag("role", userRole);
    } catch (e) {
      console.error("Unknown sentry failure " + e);
    }

    try {
      (window as any).gtag("set", { User_Role: userRole, Team_Name: currentTeam });
      (window as any).gtag("config", this.getGAEnvId(), {
        custom_map: { dimension4: "User_Role", dimension5: "Team_Name" },
        send_page_view: false,
      });
    } catch (e) {
      console.error("Unknown Google Analytics failure " + e);
    }
    this.startGoogleAnalytics(user, activeTeam);

    if (activeTeam) {
      const role: string = activeTeam.role ? activeTeam.role : "";
      if (plan_name && plan_name === "Free Plan") {
        try {
          if ((window as any).Beamer) (window as any).Beamer.destroy();
        } catch (e) {
          console.error("Unknown Beamer failure on destroy" + e);
        }
      } else {
        const beamerParams = {
          user_id: user.id,
          user_email: user.email,
          user_firstname: user.name,
          first_visit_unread: 0,
          user_role: role,
          team: currentTeam,
          teamid: activeTeam.account_id,
          plan: plan_name,
          filter: [activeTeam.account_id],
        };
        this.updateBeamerAux(beamerParams);
      }

      try {
        if ((window as any).LogRocket && mixpanelRef)
          (window as any).LogRocket.getSessionURL(function (sessionURL: any) {
            mixpanelRef.people?.set({ "LogRocket Url": sessionURL });
            mixpanelRef.identify(user.id);
          });
      } catch (e) {
        console.error("Unknown mixpanel failure " + e);
      }

      try {
        if ((window as any).mixpanel && (window as any).smartlook) {
          const dashboardSessionURL = (window as any).smartlook.playUrl;
          if (dashboardSessionURL) {
            mixpanelRef.people?.set({ "Smartlook Url": dashboardSessionURL });
            mixpanelRef.identify(user.id);
          }
        }
      } catch (e) {
        console.error("Unknown mixpanel failure " + e);
      }

      try {
        if ((window as any).sendinblue && currentTeam) {
          (window as any).sendinblue.identify(user.email, {
            user_id: user.id,
            email: user.email,
            distinct_id: user.id,
            created: user_createdAt,
            name: user.name,
            role: userRole,
            team: currentTeam,
            offering: offeringType,
            plan: plan_name,
          });
        }
      } catch (e) {
        console.error("Unknown sendinblue failure " + e);
      }
    }
  },

  setMixPanelUserProperties(user: any, activeTeam: any, activeModel?: any, userTeams?: any) {
    const mixpanelRef = getMixpanelRef();
    try {
      const userId = user?.id || activeTeam?.id;
      if (!userId) {
        console.info("User id not found while setting mixpanel user properties.");
        return;
      }

      let userName = user?.name || activeTeam?.user_name;
      let userEmail = user?.email || activeTeam?.user_email;
      let userPlan = activeTeam?.plan_name || null;

      const lastUsed: any = this.getLastUsedProps();
      if (lastUsed && lastUsed.email && lastUsed.name && lastUsed.plan) {
        userName = !userName ? lastUsed.name : userName;
        userEmail = !userEmail ? lastUsed.email : userEmail;
        userPlan = !userPlan ? lastUsed.plan : userPlan;
      }

      mixpanelRef.people?.set({
        $email: userEmail,
        $distinct_id: userId,
        $name: userName,
        UserId: userId,
      });
      mixpanelRef.register({
        $email: userEmail,
        $name: userName,
        Source: "App",
      });

      if (user?.creation_time) {
        mixpanelRef.people?.set("Creation_time", user.creation_time);
      }

      if (userPlan) {
        mixpanelRef.people?.set({
          Plan: userPlan,
        });
        mixpanelRef.register({
          Plan: userPlan,
        });
      }

      if (activeTeam) {
        mixpanelRef.people?.set({
          Role: activeTeam.role,
          Plan: activeTeam.plan_name,
        });
        mixpanelRef.register({
          "Current Subscription": activeTeam.account_name,
          Plan: activeTeam.plan_name,
        });
        mixpanelRef.set_group("Subscription", activeTeam.account_name);
        mixpanelRef.get_group("Subscription", activeTeam.account_name)?.set({
          $name: activeTeam.account_name,
          "Subscription Id": activeTeam.account_id,
          Plan: activeTeam.plan_name,
          Creation_time: activeTeam.creation_time,
          "Organization Id": activeTeam.organization_id,
          "User Email": activeTeam.user_email,
        });
        try {
          const mpData = { email: user.email, name: user.name, plan: activeTeam.plan_name };
          const expiryDate = new Date();
          expiryDate.setMonth(expiryDate.getMonth() + 1);
          const cookies: any = $cookies;
          if (cookies) {
            cookies.set("enlyft.lastused", JSON.stringify(mpData), expiryDate);
          }
        } catch (e) {
          console.error("Error setting cookies.");
        }
      }

      if (activeModel && userTeams) {
        const subscriptionNames: any = userTeams.map((account: any) => account.account_name);
        if (userTeams && Array.isArray(userTeams) && userTeams.length > 0) {
          userTeams.forEach(function (team) {
            mixpanelRef.get_group("Subscription", team.account_name).unset("Role");
          });
          mixpanelRef.people?.set({
            Subscription: subscriptionNames,
          });
          if (activeTeam) {
            mixpanelRef.get_group("Subscription", activeTeam.account_name)?.set({
              $name: activeTeam.account_name,
              "Subscription Id": activeTeam.account_id,
              Plan: activeTeam.plan_name,
              Creation_time: activeTeam.creation_time,
              "Organization Id": activeTeam.organization_id,
              "User Email": activeTeam.user_email,
            });
          }
        }
        if (activeModel && activeModel.name) {
          mixpanelRef.register({
            "Current Offering": activeModel.name,
          });
        }
      }
      mixpanelRef.identify(user?.id);
    } catch (e) {
      console.error("Unknown mixpanel failure " + e);
    }
  },

  setMixPanelPropertiesForActiveTeam(activeTeam: any) {
    const mixpanelRef = getMixpanelRef();
    if (!activeTeam) {
      console.error("activeTeam missing in setMixPanelPropertiesForActiveTeam call.", activeTeam);
      return;
    }
    if (!activeTeam.user_id || !activeTeam.user_email || !activeTeam.user_name || !activeTeam.role || !activeTeam.creation_time || !activeTeam.account_id || !activeTeam.organization_id) {
      console.error("Cannot set Mixpanel user properties due to missing active team properties.");
      return;
    }
    mixpanelRef.people?.set({
      USER_ID: activeTeam.user_id,
      $email: activeTeam.user_email,
      $distinct_id: activeTeam.user_id,
      $name: activeTeam.user_name,
      Role: activeTeam.role,
    });
    mixpanelRef.identify(activeTeam.user_id);

    mixpanelRef.set_group("Subscription", activeTeam.account_name);
    mixpanelRef.get_group("Subscription", activeTeam.account_name)?.set({
      $name: activeTeam.account_name,
      "Subscription Id": activeTeam.account_id,
      Plan: activeTeam.plan_name,
      Creation_time: activeTeam.creation_time,
      "Organization Id": activeTeam.organization_id,
      "User Email": activeTeam.user_email,
    });
  },

  updateBeamer(user?: any, activeTeam?: any, activeModel?: any): void {
    if (!user || !activeTeam) {
      return;
    }

    const planName: string = activeTeam.plan_name ? activeTeam.plan_name : "";
    const role: string = activeTeam.role ? activeTeam.role : "";
    const offering: string = activeModel && activeModel.model_type ? activeModel.model_type : "";
    const beamerParams = {
      user_id: user.id,
      user_email: user.email,
      user_firstname: user.name,
      first_visit_unread: 0,
      user_role: role,
      team: activeTeam.account_name,
      teamid: activeTeam.account_id,
      plan: planName,
      filter: [activeTeam.account_id],
      offering: offering,
    };
    this.updateBeamerAux(beamerParams);
  },

  updateBeamerAux(beamerParams: any): void {
    let beamerInstance: any = (window as any).Beamer;
    if (!beamerInstance) {
      // Beamer might be not loaded when this is executed
      const maxTries = 50;
      let tries = 0;
      const tryUpdateBeamer = () => {
        if ((window as any).Beamer) {
          try {
            beamerInstance = (window as any).Beamer;
            if (beamerInstance) {
              try {
                beamerInstance.update(beamerParams);
                beamerInstance.init();
              } catch (e) {
                console.error("Unknown Beamer failure on updating settings: " + e);
              }
            }
          } catch (ex) {
            console.error("Error on Beamer settings update" + ex);
          }
        } else {
          setTimeout(() => {
            if (tries < maxTries) {
              tries++;
              tryUpdateBeamer();
            } else {
              console.error("Error: Beamer not found for settings update");
            }
          }, 50);
        }
      };
      tryUpdateBeamer();
    } else {
      try {
        beamerInstance.update(beamerParams);
        beamerInstance.init();
      } catch (e) {
        console.error("Unknown Beamer failure on utils updating settings: " + e);
      }
    }
  },

  updateIntercom(user: IUser, activeTeam: any, activeModel: any, totalOfferings: any): void {
    if (!user || !activeTeam) {
      return;
    }
    const currentTeam: string = activeTeam && activeTeam.account_name ? activeTeam.account_name : "";
    const offeringType: string = activeModel && activeModel.model_type ? activeModel.model_type : "";
    const logrocketURL: string = "https://app.logrocket.com/8fdiaq/mui/sessions?u=" + user.id;
    const plan_name: string = activeTeam && activeTeam.plan_name ? activeTeam.plan_name : "";
    const name: string = user?.name || "";
    const email: string = user?.email || activeTeam.user_email;
    const role: string = activeTeam?.role || "";

    try {
      (window as any).Intercom("update", {
        email: email,
        name: name,
        role: role,
        team: currentTeam,
        offering: offeringType,
        offerings: totalOfferings,
        logrocket: logrocketURL,
        plan: plan_name,
        appversion: this.getCurrentVersion(),
      });
    } catch (e) {
      console.error("Unknown Intercom failure " + e);
    }
  },

  getGAEnvId(): string {
    const envKey: any = {
      localhost: "UA-43668823-8",
      "black-dust-5551.idatalabs.com": "UA-43668823-8",
      "raspy-wood-1197.idatalabs.com": "UA-43668823-7",
      "enlyft.com": "UA-43668823-6",
      "app.enlyft.com": "UA-43668823-6",
    };

    const hostname = window.location.hostname;
    const ga_env_id = envKey[hostname] || "UA-43668823-8";

    return ga_env_id;
  },

  startGoogleAnalytics(user: IUser, activeTeam: any): void {
    try {
      // send user id to GA
      if (activeTeam) {
        const currentTeam: any = activeTeam.account_name;
        const role: any = activeTeam.role;
        const planName: any = activeTeam.plan_name;

        (window as any).gtag("set", { User_Role: role, Team_Name: currentTeam, Plan: planName });
        (window as any).gtag("config", this.getGAEnvId(), {
          custom_map: { dimension4: "User_Role", dimension5: "Team_Name", dimension7: "Plan" },
          send_page_view: false,
        });
      }

      if (user?.name && user.email && user.id) {
        const gaId: any = this.updateUserName(user.name) + "-" + user.id;
        const domain: any = user.email.substring(user.email.lastIndexOf("@") + 1);
        const name: any = user.name;
        const fullName: any = name.match(/.{1,1}/g).join("-");

        // set the user and send login event to GA
        (window as any).gtag("set", { user_id: gaId });
        (window as any).gtag("event", "login", gaId);
        (window as any).gtag("set", { MUI_ID: gaId, User_Domain: domain, MUI_User: fullName });
        (window as any).gtag("config", this.getGAEnvId(), {
          custom_map: { dimension2: "MUI_ID", dimension3: "User_Domain" },
          send_page_view: false,
        });
        (window as any).gtag("event", "ID_Domain_Metric", gaId + "_" + domain);
        (window as any).gtag("config", this.getGAEnvId(), {
          custom_map: { dimension6: "MUI_User" },
          send_page_view: false,
        });
        (window as any).gtag("event", "App_Version", this.getCurrentVersion());
        (window as any).gtag("set", { App_Version: this.getCurrentVersion() });
        (window as any).gtag("config", this.getGAEnvId(), {
          custom_map: { dimension8: "App_Version" },
          send_page_view: false,
        });
      }
    } catch (e) {
      console.error("Unknown google analytics failure " + e);
    }
  },

  setGACustomEvent(user: IUser, activeTeam: IUserTeam, type: string, label: string, value: number): void {
    if (isBrowserExtension) return;

    const name: any = user?.name ? this.updateUserName(user.name) : null;
    const gaId: any = user?.name && user.id ? this.updateUserName(user.name) + "-" + user.id : null;
    const domain: any = user?.email ? user.email.substring(user.email.lastIndexOf("@") + 1) : null;
    const updatedName: any = user.name && user.name.match(/.{1,1}/g) ? user.name : "";
    const fullName: any = updatedName ? updatedName.match(/.{1,1}/g).join("-") : null;

    const currentTeam: any = activeTeam ? activeTeam.account_name : null;
    const role: any = activeTeam ? activeTeam.role : null;
    const planName: any = activeTeam ? activeTeam.plan_name : null;

    try {
      if (typeof window === "undefined") return; // happens when extension is loaded
      (window as any).gtag("config", this.getGAEnvId(), {
        custom_map: {
          dimension2: "MUI_ID",
          dimension3: "User_Domain",
          dimension4: "User_Role",
          dimension5: "Team_Name",
          dimension6: "MUI_User",
          dimension7: "Plan",
        },
        send_page_view: false,
      });

      (window as any).gtag("event", type, {
        event_category: name,
        event_label: label,
        value: value,
        MUI_ID: gaId,
        User_Domain: domain,
        User_Role: role,
        Team_Name: currentTeam,
        MUI_User: fullName,
        Plan: planName,
      });
    } catch (e) {
      console.error("Unknown google analytics failure" + e);
    }
  },

  sendMixPanelEvent(eventName: string, eventData?: any) {
    const mixpanelRef = getMixpanelRef();

    if (isBrowserExtension) return;
    try {
      let path = window && window.location && window.location.pathname ? window.location.pathname : "";
      try {
        path = utils.getFormattedPath(path) ? utils.getFormattedPath(path) : path.replace("/", "").replace(/\b[a-z]/g, (match) => match.toUpperCase());
      } catch (e) {
        console.error("Unknown MixPanel failure " + e);
      }
      const eventProperties: any = {
        Page: path,
        Source: "App",
      };
      try {
        const vuex: any = localStorage.getItem("vuex");
        const loginStore = vuex && JSON.parse(vuex).login ? JSON.parse(vuex).login : null;
        if (loginStore && loginStore.user) {
          const loginStore = JSON.parse(vuex).login;
          const user = loginStore.user;
          const lastUser: any = utils.getLastUsedProps();
          if (user?.id && lastUser && lastUser.plan) {
            const userEmail = !user.email ? lastUser.email : user.email;
            const userName = !user.name ? lastUser.name : user.name;
            const plan = lastUser.plan;

            const peopleProperties: any = {
              $distinct_id: user.id,
              UserId: user.id,
            };

            if (userName) {
              peopleProperties["$name"] = lastUser.name;
              eventProperties["$name"] = lastUser.name;
            }
            if (plan) {
              peopleProperties["Plan"] = plan;
              eventProperties["Plan"] = plan;
            }
            if (userEmail) {
              peopleProperties["$email"] = userEmail;
            }
            mixpanelRef.people?.set(peopleProperties);
            mixpanelRef.register(eventProperties);
            mixpanelRef.identify(user.id);
          }
        } else {
          console.info("Mixpanel event ignored as user was not found.", vuex);
        }
      } catch (e) {
        console.error("Unknown MixPanel failure " + e);
      }
      Object.assign(eventProperties, eventData);

      mixpanelRef.track(eventName, eventProperties);
    } catch (ex) {
      console.error("Unknown MixPanel failure " + ex);
    }
  },

  getFormattedPath(path: string): string | null {
    try {
      const page = path.replace("/", "");
      return page.replace(/\b[a-z]/g, (match) => match.toUpperCase());
    } catch (ex) {
      console.error("Error getting path name", ex);
      return path;
    }
  },

  updateUserName(name: any): string | null {
    try {
      if (name && name.indexOf(" ") > 0) {
        name = name.substring(0, name.indexOf(" "));
        name = name
          .match(/.{1,1}/g)
          .join("-")
          .toLowerCase();
      } else {
        name = name
          .match(/.{1,1}/g)
          .join("-")
          .toLowerCase();
      }
      return name;
    } catch (e) {
      console.error("Error updating username " + e);
    }
    return null;
  },
  getTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  },
  trackIntercomOpen(): void {
    (window as any).Intercom("show", {
      app_id: "ejcd64x5",
    });
  },

  triggerIntercomWithMessage(message: string): void {
    (window as any).Intercom("showNewMessage", message);
  },
  trackIntercomEvent(eventname: string, metadata: any): void {
    (window as any).Intercom("trackEvent", eventname, metadata);
    setTimeout(() => {
      (window as any).Intercom("update", { update_time: utils.getTimestamp() });
    }, 4000);
  },
  trackButtonClick(label: string, value: string): void {
    try {
      (window as any).gtag("event", "Interaction", {
        event_category: "ButtonClick",
        event_label: label,
        value: value,
      });
    } catch (e) {
      console.error("Unknown google analytics failure" + e);
    }

    try {
      utils.trackIntercomEvent("ButtonClick" + "_" + label, { Value: value });
    } catch (e) {
      console.error("Unknown intercom event analytics failure" + e);
    }

    try {
      utils.sendMixPanelEvent("Interaction", { Element: label, Action: "Click", Value: value });
    } catch (e) {
      console.error("Unknown mixpanel event failure" + e);
    }
  },

  getCurrentVersion(): string {
    let buildVersion = "";
    if (this.BUILD_VERSION && typeof this.BUILD_VERSION === "string" && this.BUILD_VERSION.length >= 10) {
      buildVersion = "v1." + this.BUILD_VERSION.substr(3, 6);
    }
    return buildVersion;
  },

  isValidUrl(input: string) {
    // eslint-disable-next-line
    const validUrlRegExp: RegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return input.match(validUrlRegExp) !== null;
  },

  getValidJsonObject(json: any, validProperties: any[]) {
    const validObject: any = {};
    let validJson: any = json;
    if (typeof json === "string") {
      validJson = JSON.parse(json);
    }
    try {
      for (const [key, value] of Object.entries(validJson)) {
        if (validProperties.includes(key)) {
          validObject[key] = value;
        } else {
          console.error("Ignoring invalid key");
        }
      }
    } catch (err) {
      console.error("Error getting valid json object");
      return {};
    }

    return validObject;
  },

  getBlackListedDomains(): string[] {
    const blackListedDomains = [
      "hotmail.com",
      "outlook.com",
      "yahoo.com",
      "qq.com",
      "live.com",
      "163.com",
      "msn.com",
      "hotmail.co.uk",
      "icloud.com",
      "aol.com",
      "comcast.net",
      "googlemail.com",
      "mail.ru",
      "126.com",
      "live.co.uk",
      "me.com",
      "hotmail.fr",
      "yandex.ru",
      "web.de",
      "yahoo.com.br",
      "yahoo.co.uk",
      "gmx.de",
      "ymail.com",
      "verizon.net",
      "mail.com",
      "mac.com",
      "rocketmail.com",
      "sina.com",
      "yahoo.fr",
      "sbcglobal.net",
      "yahoo.co.jp",
      "att.net",
      "rr.com",
      "bellsouth.net",
      "windowslive.com",
      "wanadoo.fr",
      "yahoo.com.tw",
      "wp.pl",
      "free.fr",
      "gmx.net",
      "yahoo.es",
      "laposte.net",
      "yahoo.co.in",
      "gmail.com",
      "bol.com.br",
      "rambler.ru",
      "ya.ru",
      "hotmail.it",
      "gmx.com",
      "onmicrosoft.com",
      "naver.com",
      "outlook.fr",
      "live.nl",
      "outlook.jp",
      "outlook.es",
      "live.fr",
      "outlook.it",
      "ocn.ne.jp",
      "hotmail.co.jp",
      "outlook.de",
      "getnada.com",
      "xs4all.nl",
      "btinternet.com",
      "hotmail.es",
      "kpn.com",
      "outlook.kr",
      "protonmail.",
      "outlook.com.au",
      "rogers.com",
      "live.it",
      "live.jp",
      "hotmail.de",
      "live.in",
      "live.dk",
      "mailinator.com",
      "33mail.com",
    ];
    return blackListedDomains;
  },
  formatName(name: string) {
    return name
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .trim();
  },
  setMixpanelUTMProperties(params: any) {
    const mixpanelRef = getMixpanelRef();
    try {
      mixpanelRef.register({
        UTM_Source: params.utm_source,
      });
    } catch (e) {
      console.error("Error setting UTM properties." + e);
    }
  },
  getUTMParams(path: string) {
    try {
      const url = new URL(path);
      const params = new URLSearchParams(url.search);
      const utm_campaign = params.get("utm_campaign") || "";
      const utm_source = params.get("utm_source") || "Direct";
      const utm_medium = params.get("utm_medium") || "";
      const utm_term = params.get("utm_term") || "";
      const utm_content = params.get("utm_content") || "";
      const utmData = {
        utm_campaign: utm_campaign,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_term: utm_term,
        utm_content: utm_content,
      };
      return utmData;
    } catch (e) {
      console.error("Error sending UTM event." + e);
    }
  },

  sendSIBEvent(eventName: string, eventData: any, activeTeam?: any) {
    try {
      const userProperties = activeTeam
        ? {
            user_email: activeTeam.user_email,
            user_name: activeTeam.user_name,
          }
        : {};
      const data = activeTeam
        ? {
            id: activeTeam.user_id,
            data: eventData,
          }
        : { data: eventData };

      if ((window as any).sendinblue) {
        (window as any).sendinblue.track(eventName, userProperties, data);
      }
    } catch (ex) {
      console.error("Unknown SendInBlue failure " + ex);
    }
  },

  getLastUsedProps() {
    const mixpanelRef = getMixpanelRef();
    const cookies = $cookies;
    if (cookies) {
      const lastUsed: any = cookies.get("enlyft.lastused");
      const userId: string = cookies.get("user");
      if (lastUsed && lastUsed.email && lastUsed.name && lastUsed.plan && (window as any).mixpanel && mixpanelRef) {
        mixpanelRef.people?.set({
          $email: lastUsed.email,
          $name: lastUsed.name,
          Plan: lastUsed.plan,
        });
        mixpanelRef.register({
          $email: lastUsed.email,
          $name: lastUsed.name,
          Plan: lastUsed.plan,
          Source: "App",
        });
        if (userId) {
          mixpanelRef.identify(userId);
        } else {
          mixpanelRef.identify();
        }
        return lastUsed;
      }
    }
    return {};
  },

  genUuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  btnRoute(routeName: string, mpElement?: any) {
    if (!routeName) {
      return;
    }
    if (mpElement) {
      utils.sendMixPanelEvent("Interaction", { Element: mpElement, Action: "Click", Route: routeName });
    }
    //TBD: Not sure why this is not working
    // router.push({
    //   name: routeName,
    // });
  },
  getUrlParameter(param: string) {
    const result: any = {};
    const params = (window.location.search.split("?")[1] || "").split("&");
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        const paramParts = params[param].split("=");
        result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
      }
    }
    const results = result[param] ? result[param] : null;
    return results;
  },
  genRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  },
  getInitial(fullName: string): string {
    let initial = "";
    const nameparts: any[] = fullName
      .replace(/[^a-zA-Z ]+/g, "")
      .trim()
      .split(" ");
    initial = nameparts[0].charAt(0).toUpperCase();
    if (nameparts.length > 1 && /[a-z]/i.test(nameparts[1])) initial += nameparts[1].charAt(0).toUpperCase();
    return initial;
  },
  getScoreColor(score: number): string {
    let color = "#479A63";
    if (score < MODERATE_CONFIDENCE_THRESHOLD && score >= LOW_CONFIDENCE_THRESHOLD) {
      color = "#A6BA7D";
    }
    if (score < LOW_CONFIDENCE_THRESHOLD) {
      color = "#F9D781";
    }
    return color;
  },
  objArrayToCSV(data: any) {
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    let result: any;
    let ctr: number;

    if (data === null || !data.length) {
      return null;
    }

    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item: any) => {
      ctr = 0;
      keys.forEach((key: any) => {
        if (ctr > 0) {
          result += columnDelimiter;
        }

        result += typeof item[key] === "string" && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  },
  copyTextToClipboard(text: string, successCallback?: () => void) {
    navigator.clipboard.writeText(text).then(
      function () {
        if (successCallback) successCallback();
      },
      function (err) {
        console.error("Could not copy text: ", err);
      },
    );
  },
  getNumberWithCommas(num: any) {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  },
  // to change query param without components reloading
  // using "$router.replace" and "$router.push" created() and mounted() will run again
  // use carefully to avoid potential conflicts with "vue-router"
  setURLSearchParam(key: string, value: string, pushCurrentToHistory: boolean = true) {
    if ("URLSearchParams" in window) {
      if (pushCurrentToHistory) {
        window.history.pushState(
          {
            ...window.history.state,
            randomData: window.Math.random(),
          },
          document.title,
          window.location.href,
        );
      }
      const searchParams = new URLSearchParams(window.location.search);
      searchParams?.set(key, value);
      const newHref = window.location.origin + window.location.pathname + "?" + searchParams.toString();
      window.history.replaceState(window.history.state, document.title, newHref);
    }
  },
  validateURL(url: string): string {
    // default to ssl
    if (url && !url.startsWith("http")) return `https://${url}`;
    return url;
  },
  capitalizeFirstLetter(string: string): string {
    if (!string) return "";
    return string?.charAt(0).toUpperCase() + string.slice(1);
  },
  encrypt(value) {
    if (!RSA_PUBLIC_KEY) {
      console.error("Error: public key missing");
      return;
    }
    try {
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----${RSA_PUBLIC_KEY}-----END PUBLIC KEY-----`);
      return encrypt.encrypt(value);
    } catch (e) {
      console.error("Error: ", e);
      return;
    }
  },
  generateId(fullString) {
    try {
      if (fullString) {
        return fullString.split(" ").join("_").toLowerCase();
      } else {
        return "";
      }
    } catch (e) {
      console.error("Error generating attribute id", e);
    }
  },
  removeHttp(url) {
    return url.replace(/^http(s)?:\/\//, "");
  },
  isValidImgURL(url, timeout = 5000) {
    return new Promise(function (resolve) {
      const img = new Image();
      img.onerror = img.onabort = function () {
        clearTimeout(timer);
        resolve(false);
      };
      img.onload = function () {
        clearTimeout(timer);
        resolve(true);
      };
      const timer = setTimeout(function () {
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = "//!!!!/test.jpg";
        resolve(false);
      }, timeout);
      img.src = url;
    });
  },
};
export default utils;
