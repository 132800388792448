/* istanbul ignore file */
import api from "./apiCalls";
import { ActionTree, Module } from "vuex";
import { IRootState } from "@/store";

export const state: IAdminState = {
  tenants: undefined,
};

export interface IAdminState {
  tenants: any;
}

const actions: ActionTree<IAdminState, IRootState> = {
  getCSAdmins(store: any, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching cs admins", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const csAdmins: [any] = data as [any];
        resolve(csAdmins);
      };
      api.getCSAdmins(teamId).then(successHandler).catch(errorHandler);
    });
  },
  demoteCSAdmin(store: any, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching cs admins", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data ? data : null);
      };
      api.demoteCSAdmin(payload.teamId, payload.userId).then(successHandler).catch(errorHandler);
    });
  },
  createCSAdmin(
    store: any,
    payload: {
      name: string;
      email: string;
      teamId: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating tenant", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.name) {
        console.error("empty name");
        reject(new Error("empty name"));
      }
      if (!payload.email) {
        console.error("empty email");
        reject(new Error("empty email"));
      }
      if (!payload.teamId) {
        console.error("empty teamId");
        reject(new Error("empty teamId"));
      }
      api
        .createCSAdmin(
          {
            name: payload.name,
            email: payload.email,
          },
          payload.teamId,
        )
        .then(successHandler)
        .catch(errorHandler);
    });
  },
  promoteCSAdmin(
    store: any,
    payload: {
      userId: string;
      teamId: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating tenant", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.userId) {
        console.error("empty userId");
        reject(new Error("empty userId"));
      }
      if (!payload.teamId) {
        console.error("empty teamId");
        reject(new Error("empty teamId"));
      }
      api.promoteCSAdmin(payload.teamId, payload.userId).then(successHandler).catch(errorHandler);
    });
  },
  getTenants(): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching tenants", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const tenants: [any] = data as [any];
        resolve(tenants);
      };
      api.getTenants().then(successHandler).catch(errorHandler);
    });
  },
  getTenant(store: any, tenantId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching tenants", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const tenants: [any] = data as [any];
        resolve(tenants);
      };
      if (!tenantId) {
        console.error("empty tenant id");
        reject(new Error("empty tenant id"));
      }
      api.getTenants(tenantId).then(successHandler).catch(errorHandler);
    });
  },
  createTenant(
    store: any,
    payload: {
      companyName: string;
      companyWebsite: string;
      nLicenses: number;
      nCredits: number;
      enlyftPlanName: string;
      userEmail: string;
      firstName: string;
      lastName: string;
      createOffering: boolean;
      notes: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating tenant", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.companyWebsite) {
        console.error("empty CompanyWebsite");
        reject(new Error("empty CompanyWebsite"));
      }
      if (!payload.companyName) {
        console.error("empty Company Name");
        reject(new Error("empty Company Name"));
      }
      if (!payload.nLicenses) {
        console.error("empty nLicenses");
        reject(new Error("empty nLicenses"));
      }
      if (!payload.nCredits) {
        console.error("empty nCredits");
        reject(new Error("empty nCredits"));
      }
      if (!payload.enlyftPlanName) {
        console.error("empty enlyftPlanName");
        reject(new Error("empty enlyftPlanName"));
      }
      if (!payload.userEmail) {
        console.error("empty userEmail");
        reject(new Error("empty userEmail"));
      }
      if (!payload.firstName) {
        console.error("empty firstName");
        reject(new Error("empty firstName"));
      }
      if (!payload.lastName) {
        console.error("empty lastName");
        reject(new Error("empty lastName"));
      }
      if (!payload.createOffering) {
        console.error("empty createOffering");
        reject(new Error("empty createOffering"));
      }
      api.createTenant(payload).then(successHandler).catch(errorHandler);
    });
  },
  createUser(
    store: any,
    payload: {
      teamId: string;
      user_name: string;
      user_email: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error adding user to the team", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.teamId) {
        console.error("empty teamId");
        reject(new Error("empty teamId"));
      }
      if (!payload.user_name) {
        console.error("empty user_name");
        reject(new Error("empty user_name"));
      }
      if (!payload.user_email) {
        console.error("empty user_email");
        reject(new Error("empty user_email"));
      }
      api.createUser(payload.teamId, payload.user_name, payload.user_email, "admin", "true").then(successHandler).catch(errorHandler);
    });
  },
  getTenantTeam(store: any, payload: { tenantId: string; teamId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching tenant team", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const team: [any] = data as [any];
        resolve(team);
      };
      if (!payload.tenantId || !payload.teamId) {
        console.error("empty tenant/team id");
        reject(new Error("empty tenant/team id"));
      }
      api.getTenantTeam(payload.tenantId, payload.teamId).then(successHandler).catch(errorHandler);
    });
  },
  getProducts(): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching products from stripe", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      api.getProducts().then(successHandler).catch(errorHandler);
    });
  },
  getPlans(store: any, payload: { productId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching plans from stripe", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
        if (!payload.productId) {
          console.error("empty product id");
          reject(new Error("empty product id"));
        }
      };

      api.getPlans(payload.productId).then(successHandler).catch(errorHandler);
    });
  },
  getInvoiceAmount(store: any, payload: { planId: string; couponId: string; licenses: number }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error getting invoice amount", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload.planId) {
        console.error("empty plan id");
        reject(new Error("empty plan id"));
      }
      api.getInvoiceAmount(payload.planId, payload.couponId, payload.licenses).then(successHandler).catch(errorHandler);
    });
  },
  getCoupons(): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error fetching coupons from stripe", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      api.getCoupons().then(successHandler).catch(errorHandler);
    });
  },

  cancelSubscriptionStatus(
    store: any,
    payload: {
      subscription_uuid: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating offering", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.subscription_uuid) {
        console.error("Missing Subscription Id");
        reject(new Error("empty Subscription id"));
        return;
      }
      api.cancelSubscriptionStatus(payload).then(successHandler).catch(errorHandler);
    });
  },

  cancelSubscriptionRenewal(
    store: any,
    payload: {
      subscription_uuid: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating offering", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.subscription_uuid) {
        console.error("Missing Subscription Id");
        reject(new Error("empty Subscription id"));
        return;
      }
      api.cancelSubscriptionRenewal(payload).then(successHandler).catch(errorHandler);
    });
  },
  changeSubscriptionPaymentStatus(
    store: any,
    payload: {
      subscription_uuid: any;
      stripeId: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating offering", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.subscription_uuid) {
        console.error("Missing Subscription Id");
        reject(new Error("empty Subscription id"));
        return;
      }
      if (!payload.stripeId) {
        console.error("Missing Stripe Id");
        reject(new Error("empty Stripe id"));
        return;
      }
      api.changeSubscriptionPaymentStatus(payload).then(successHandler).catch(errorHandler);
    });
  },

  addCreditsToExistingOffering(
    store: any,
    payload: {
      subscription_uuid: string;
      description: string;
      tenant_id: string;
      nCredits: number;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating offering", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.subscription_uuid) {
        console.error("Missing Team Id");
        reject(new Error("empty team id"));
        return;
      }

      if (!payload.description) {
        console.error("Missing description");
        reject(new Error("empty description"));
        return;
      }

      if (!payload.tenant_id) {
        console.error("Missing parent tenantID");
        reject(new Error("empty tenantID"));
        return;
      }
      if (!payload.nCredits) {
        console.error("Missing parent nCredits");
        reject(new Error("empty nCredits"));
        return;
      }
      api.addCreditsToExistingOffering(payload).then(successHandler).catch(errorHandler);
    });
  },

  createTargetingOffering(
    store: any,
    payload: {
      teamId: string;
      name: string;
      description: string;
      parent_id: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error creating offering", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!payload.teamId) {
        console.error("Missing Team Id");
        reject(new Error("empty team id"));
      }
      if (!payload.name) {
        console.error("Missing Offering name");
        reject(new Error("empty offering name"));
      }
      if (!payload.description) {
        console.error("Missing description");
        reject(new Error("empty description"));
      }
      if (!payload.parent_id) {
        console.error("Missing parent offering id");
        reject(new Error("empty parent_id"));
      }
      api.createTargetingOffering(payload).then(successHandler).catch(errorHandler);
    });
  },
  resetTeam(store: any, teamName: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("error resetting team", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const resp: any = data as any;
        resolve(resp);
      };
      if (!teamName) {
        console.error("Missing Team Name");
        reject(new Error("empty team name"));
      }
      api.resetTeam(teamName).then(successHandler).catch(errorHandler);
    });
  },
};

const namespaced: boolean = true;
export const adminStore: Module<IAdminState, IRootState> = {
  actions,
  namespaced,
};
