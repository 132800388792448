import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";

export const state: IMyAccountsState = {
  accounts: undefined,
  tags: undefined,
  segments: undefined,
  recentAccounts: undefined,
  lastRecentAccountsFetchTime: 0,
  lastRecentAccountsFetchPayload: "",
  recent: undefined,
};

export interface IMyAccountsState {
  accounts?: IAccounts;
  tags?: ITags;
  segments?: any;
  recentAccounts?: IAccounts;
  lastRecentAccountsFetchTime: number;
  lastRecentAccountsFetchPayload: string;
  recent: any;
}

export interface IAccounts {
  accounts: object;
  num_accounts?: number;
  creation_time?: any;
}

export interface ISegmentData {
  [key: string]: any;
}

// export interface ISegments {
//   segments: any[];
// }

export interface ITags {
  count: number;
  creation_time: any;
  tag_name: string;
}

const actions: ActionTree<IMyAccountsState, IRootState> = {
  getRecentAccounts(store: ActionContext<IMyAccountsState, any>, payload: { teamId: string; userId: string; skip: number; limit: number }): Promise<IAccounts> {
    return new Promise((resolve, reject) => {
      const currentTime = new Date().getTime();
      const fetchDiffTime = 5000;
      const stringifiedPayload = JSON.stringify(payload);
      // Do not fetch again if last call was made less than 5sec before
      if (currentTime - store.state.lastRecentAccountsFetchTime < fetchDiffTime && store.state.recent && store.state.lastRecentAccountsFetchPayload && store.state.lastRecentAccountsFetchPayload === stringifiedPayload) {
        resolve(store.state.recent);
        return;
      }
      store.commit("lastRecentAccountsFetchTime", currentTime);
      store.commit("lastRecentAccountsFetchPayload", stringifiedPayload);
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getRecentAccounts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const recent: IAccounts = data as IAccounts;
        if (recent) {
          store.commit("Recent", recent);
          resolve(recent);
          return;
        }
        console.error("getRecentAccounts error", "response is not IAccounts", data);
        reject(new Error("response is not IAccounts"));
      };

      if (!payload.teamId || !payload.limit) {
        errorHandler({ error: "empty parameters for getRecentAccounts API call" });
        return;
      }
      if (!payload.userId) {
        errorHandler({ error: "emply parameter userId for getRecentAccounts API call" });
      }
      api.getRecentAccounts(payload.teamId, payload.userId, payload.skip, payload.limit).then(successHandler).catch(errorHandler);
    });
  },

  getTags(store: ActionContext<IMyAccountsState, any>, payload: { teamId: string; userId: string; query?: string }): Promise<ITags> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getTags error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const tags: ITags = data as ITags;
        if (tags) {
          resolve(tags);
          return;
        }
        console.error("getTags error", "response is not ITags", data);
        reject(new Error("response is not ITags"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameters teamId for getTags API call" });
        return;
      }

      if (!payload.userId) {
        errorHandler({ error: "empty parameters userId for getTags API call" });
        return;
      }
      api.getTags(payload.teamId, payload.userId, payload.query).then(successHandler).catch(errorHandler);
    });
  },

  getAccountsMatchingTag(store: ActionContext<IMyAccountsState, any>, payload: { accountId: string; ownerId: string; tag_name: string; skip: number; limit: number }): Promise<IAccounts> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getAccountsMatchingTag error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const recent: IAccounts = data as IAccounts;
        if (recent) {
          resolve(recent);
          return;
        }
        console.error("getAccountsMatchingTag error", "response is not IAccounts", data);
        reject(new Error("response is not IAccounts"));
      };

      if (!payload.accountId || !payload.tag_name) {
        errorHandler({ error: "empty parameters for getAccountsMatchingTag API call" });
        return;
      }
      api.getAccountsWithTag(payload.accountId, payload.ownerId, payload.tag_name, payload.skip, payload.limit).then(successHandler).catch(errorHandler);
    });
  },

  downloadAccounts(store: ActionContext<IMyAccountsState, any>, payload: { teamId: string; modelId: string; segmentId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("downloadAccounts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const accounts: any = data;
        if (accounts) {
          resolve(accounts);
          return;
        }
        console.error("downloadAccounts error", "response is not an object", data);
        reject(new Error("response is not object"));
      };

      if (!payload.segmentId) {
        errorHandler({ error: "empty parameters for downloadAccounts API call" });
        return;
      }
      api.downloadAccounts(payload.teamId, payload.modelId, payload.segmentId).then(successHandler).catch(errorHandler);
    });
  },

  setRecentAccounts(store: ActionContext<IMyAccountsState, any>, accounts: IAccounts): void {
    store.commit("RecentAccounts", accounts);
  },
};

const getters: GetterTree<IMyAccountsState, IRootState> = {
  getRecentAccounts(state: IMyAccountsState): IAccounts | null {
    const { accounts } = state;
    return accounts || null;
  },
  getTags(state: IMyAccountsState): ITags | null {
    const { tags } = state;
    return tags || null;
  },
  getAccountsMatchingTag(state: IMyAccountsState): IAccounts | null {
    const { accounts } = state;
    return accounts || null;
  },
  RecentAccounts(state: IMyAccountsState): IAccounts | null {
    const { recentAccounts } = state;
    return recentAccounts || null;
  },
};

const mutations: MutationTree<IMyAccountsState> = {
  RecentAccounts(state: IMyAccountsState, accounts: any): void {
    state.recentAccounts = accounts;
  },
  lastRecentAccountsFetchTime(state: IMyAccountsState, value: number): void {
    state.lastRecentAccountsFetchTime = value;
  },
  lastRecentAccountsFetchPayload(state: IMyAccountsState, value: any): void {
    state.lastRecentAccountsFetchPayload = value;
  },
  Recent(state: IMyAccountsState, recent: any): void {
    state.recent = recent;
  },
};

const namespaced: boolean = true;

export const myaccountsStore: Module<IMyAccountsState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
