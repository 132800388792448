import { Rights, User } from "@/shared/user";
import { useGetters } from "@/shared/utils/storeHelpers";
import { defineStore } from "pinia";
import { getUser, patchUser } from "@/stores/user/apiCalls";
import type { userPersonalData } from "@/stores/user/types";

export const useUser = defineStore("user", {
  state: () => ({
    loggedInUser: useGetters("login", ["getUser"]).getUser as User,
    activeTeam: useGetters("team", ["activeTeam"]).activeTeam,
    featuresLimit: useGetters("team", ["featuresLimit"]).featuresLimit,
  }),
  getters: {
    isAdmin(): boolean {
      return this.loggedInUser?.is(Rights.Admin, this.activeTeam);
    },
    isViewer(): boolean {
      return this.loggedInUser?.is(Rights.Viewer, this.activeTeam);
    },
    isSales(): boolean {
      return this.loggedInUser?.is(Rights.Sales, this.activeTeam);
    },
    isEnlyftAdmin(): boolean {
      return this.loggedInUser?.is(Rights.EnlyftAdmin, this.activeTeam);
    },
    isFreemium(): boolean {
      return this.loggedInUser?.can(Rights.FreemiumUser, this.activeTeam);
    },
    canSetup(): boolean {
      return this.loggedInUser?.can(Rights.SeeSetupTab, this.activeTeam);
    },
    isWorkflowAddOn(): boolean {
      return this.loggedInUser?.canFeature(Rights.EnableAddOn, this.featuresLimit);
    },
    canRedeemAccounts(): boolean {
      return this.loggedInUser?.can(Rights.RedeemAccounts, this.activeTeam);
    },
    // NOTE: the feature account_redemption_bulk is to check if a plan allows to bulk save, not the user. For user permission check the user role on team/subscription
    canFeatureRedeemAccounts(): boolean {
      return this.loggedInUser?.canFeature(Rights.AccountRedemptionBulk, this.featuresLimit);
    },
    canDiscover(): boolean {
      return this.loggedInUser?.can(Rights.SeeDiscoverTab, this.activeTeam);
    },
    canEnableCRM(): boolean {
      return this.loggedInUser?.canFeature(Rights.EnableCRM, this.featuresLimit);
    },
    hasEbsIntensity(): boolean {
      return this.loggedInUser?.canFeature(Rights.EnableEbsIntensity, this.featuresLimit);
    },
    hasIcp(): boolean {
      return this.loggedInUser?.canFeature(Rights.IcpFitScore, this.featuresLimit);
    },
    hasDashboardPage(): boolean {
      return this.loggedInUser?.canFeature(Rights.DashboardPage, this.featuresLimit);
    },
    hasSolutionPivot(): boolean {
      return this.loggedInUser?.canFeature(Rights.SolutionPivot, this.featuresLimit);
    },
    hasSolutionAndOutreach(): boolean {
      return this.loggedInUser?.canFeature(Rights.SolutionAndOutreach, this.featuresLimit);
    },
    hasChatGptEmailer(): boolean {
      return this.loggedInUser?.canFeature(Rights.ChatGptEmailer, this.featuresLimit);
    },
    hasGenAiLanguage(): boolean {
      return this.loggedInUser?.canFeature(Rights.GenAiLanguage, this.featuresLimit);
    },
    hasAiDataPoints(): boolean {
      return this.loggedInUser?.canFeature(Rights.IcpFitScore, this.featuresLimit);
    },
  },
  actions: {
    async updateUser(userId: string, payload: userPersonalData) {
      try {
        await patchUser(userId, payload);
      } catch (err) {
        console.error("patchUser", err);
      }
    },
    async getUser(userId: string) {
      try {
        return await getUser(userId);
      } catch (err) {
        console.error("getUser", err);
      }
    },
  },
});
